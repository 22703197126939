import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../assets/images/profile.jpg";
import { Button, Layout, Menu, theme } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
// import DashboardIcon from "@mui/icons-material/Dashboard";

import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import { Footer } from "antd/es/layout/layout";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import HistoryIcon from "@mui/icons-material/History";

import "./MainLayout.css";
import axios from "axios";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  // State to manage selected key of menu
  const [selectedKey, setSelectedKey] = useState(() => {
    return localStorage.getItem("selectedKey") || "whatsappcloud";
  });

  // Function to handle menu item click
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    if (key === "signout") {
      // Handle signout
    } else {
      navigate(key);
    }
  };

  useEffect(() => {
    // Store the selected key in local storage when it changes
    localStorage.setItem("selectedKey", selectedKey);
  }, [selectedKey]);

  const handleLogout = async () => {
    try {
      const response = await axios.post("/api/user/logout");

      if (response.status === 200) {
        // Clear local storage
        localStorage.clear();

        // Show success message and navigate
        toast.success("Logout successful!");
        navigate("/");
      } else {
        // Handle unexpected response status
        toast.error("Unexpected response while logging out.");
      }
    } catch (error) {
      // Handle network errors or other issues
      console.error(error);
      toast.error("Something went wrong while logging out.");
    }
  };

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Retrieve the user's name from local storage
    const storedFirstName = localStorage.getItem("firstname");
    const storedLastName = localStorage.getItem("lastname");
    const storedEmail = localStorage.getItem("email");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const [role, setRole] = useState(""); // Add a state variable to store user role

  useEffect(() => {
    // Retrieve the user's role from local storage
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);

  // Function to check if the user has admin role
  const isAdmin = () => {
    return role === "admin";
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar Starts */}
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          // boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          overflowY: "auto", // Make sidebar scrollable
          height: "100vh",
        }}
        className="sticky-sidebar"
      >
        <div theme="light" className="logo">
          <h2 className="text fs-3 text-center py-3 mb-0">
            <span className="sm-logo text-primary">
              <TempleBuddhistIcon />
            </span>
            <span className="lg-logo  text-primary">WABA-Reports</span>
          </h2>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedKey]} // Pass selectedKeys prop to highlight selected item
          onClick={handleMenuClick}
          items={[
            {
              label: "Whatsapp Cloud",
              key: "whatsappcloud",
              icon: <OndemandVideoIcon className="fs-4" />,
            },

            // {
            //   label: "WA-Team V1",
            //   key: "wateamvone",
            //   icon: <ImportantDevicesIcon className="fs-4" />,
            // },
            {
              label: "WA-Team V2",
              key: "wateamvtwo",
              icon: <HistoryIcon className="fs-4" />,
            },
            {
              label: "API Response Error",
              key: "apiresponseerror",
              icon: <HistoryIcon className="fs-4" />,
            },
            {
              label: "WA-RedLava",
              key: "waredlava",
              icon: <HistoryIcon className="fs-4" />,
            },
          ]}
        />
      </Sider>
      {/* Sidebar Ends */}

      {/* Sub Layout Starts */}
      <Layout className="sticky-layout">
        {/* Navbar Starts */}
        <Header
          className="d-flex justify-content-between ps-1 pe-5 sticky-header"
          style={{
            padding: 0,
            // background: colorBgContainer,
            background: colorBgContainer,
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex gap-3 align-items-center dropdown">
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <h5 className="mb-0">{firstname + " " + lastname}</h5>
                  <img
                    width={32}
                    height={32}
                    style={{ borderRadius: "50%" }}
                    src={profile}
                    alt="Profile"
                  />
                </div>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <div
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/view-profile"
                  >
                    <h5 className="mb-0">{firstname + " " + lastname}</h5>
                    <p className="mb-0">{email}</p>
                  </div>
                  <hr />
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/dashboard/profile"
                  >
                    View Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{
                      height: "auto",
                      lineHeight: "20px",
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <i
                      className="ri-shut-down-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Logout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        {/* Navbar Ends */}

        {/* Dashboar Content Starts */}
        <Content
          className="scrollable-content"
          style={{
            margin: "0px 5px",
            padding: 2,
            minHeight: 280,
            background: colorBgContainer,
            position: "relative", // Add this to make the position of the content relative
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
        {/* Dashboar Content Ends */}
        <Footer
          style={{
            textAlign: "center",
            // background: colorBgContainer,
            // background: "#68bb59",
            background: "#E6F4FF",
            boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
            marginTop: "0px",
            height: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="mb-0">
            © The{" "}
            <span
              style={{
                color: "#0d6efd",
                // color: "#ffffff",
              }}
            >
              Mind Collective
            </span>
          </p>
        </Footer>
      </Layout>
      {/* Sub Layout Ends */}
    </Layout>
  );
};
export default MainLayout;
